//判断PC还是手机
export function isPC() {
  var flag
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || isIphone12()) {
    flag = false
  } else {
    flag = true
  }
  return flag
}
//苹果12
export function isIphone12() {
  var flag
  var userAgent = navigator.userAgent
  var ios = !!userAgent.match(/Mac OS X/) //ios终端
  if (ios && window.screen.width == 390 && window.devicePixelRatio === 3 && window.screen.height == 844) {
    flag = true
  } else {
    flag = false
  }
  return flag
}

export function isIE() {
  if (!!window.ActiveXObject || "ActiveXObject" in window || window.navigator.userAgent.indexOf("Edg") > -1) {
    return true
  } else {
    return false
  }
}

//字典数据通过code取name
export function getNameByCode(code, list) {
  let name = ''
  list.forEach(item => {
    if (item.codeCd === code) {
      name = item.codeValue
    }
  })
  return name
}

//检测兼容模式下的360浏览器
export function checkIeFor360() {
  return ((navigator.msPointerEnabled == undefined ? true : navigator.msPointerEnabled)
    && ((Number(window.screenX) ? (window.screenLeft - window.screenX != 8) : false)
      || ((navigator.userAgent.indexOf('MSIE 7.0') != -1 || navigator.userAgent.indexOf('MSIE 8.0') != -1) && console.count == undefined)));
}

// 检测极速内核下的360浏览器
export function checkChromeFor360() {
  var uas = navigator.userAgent.split(' '),
    result = false;
  // 排除ua自带标志的双核浏览器, 余下chrome,chromium及360浏览器
  if (uas[uas.length - 1].indexOf('Safari') == -1) {
    return result;
  }
  for (var key in navigator.plugins) {
    // np-mswmp.dll文件在chromium及chrome未查询到
    if (navigator.plugins[key].filename == 'np-mswmp.dll') {
      return !result;
    }
  }
  return result;
}