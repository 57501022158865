<template>
<van-pull-refresh
  v-model="refreshing"
  success-text="刷新成功"
  @refresh="onRefresh"
  :disabled="refreshDisabled"
  style="min-height: 100vh; background-color: #F5F5F5;">
  <div class="paymentBox">
    <div class="amount">￥<span class="num">{{payAmount}}</span></div>
    <div v-if="orderInfo.couponAmount" class="discount orange">已省￥{{orderInfo.couponAmount}}</div>
    <div class="receiveBox">收款方：<span class="receiveName">{{orderInfo.receiveName}}</span></div>
    <p class="payment-title">支付方式</p>
    <div class="pay-channel-list" ref="listScorll">
      <van-radio-group v-model="radio">
        <div v-for="(item,index) in orderInfo.payChannel" :key="index" class="radio-item">
          <van-cell clickable @click="()=>radioChange(item)" v-if="item!=='15'">
            <template #title>
              <div class="pay-item">
                <van-image v-if="item === '1' || item === '6'|| item === '9' || item === '13'" :src="require('../assets/images/weixin.png')" alt="" class="pay-icon" fit="contain" />
                <van-image v-else-if="item === '4' || item === '7'|| item === '8'" :src="require('../assets/images/alipay.png')" alt="" class="pay-icon" fit="contain" />
                <van-image v-else-if="item === '2'" :src="require('../assets/images/yinlian.png')" alt="" class="pay-icon" fit="contain" />
                <van-image v-else-if="item === '3'" :src="require('../assets/images/zhongxin.png')" alt="" class="pay-icon" fit="contain" />
                <van-image v-else-if="item === '12'" :src="require('../assets/images/qianbao.png')" alt="" class="pay-icon" fit="contain" />
                <van-image v-else-if="item === '14'" :src="require('../assets/images/juhema.png')" alt="" class="pay-icon" fit="contain" />
                <div class="pay-text">
                  <div class="custom-title" >{{getNameByCode(item,payChannelList)}}</div>
                  <div class="custom-tip" v-if="item=='3'">仅支持个人银行卡转账,单日/单笔限额最高5万</div>
                  <div class="custom-tip" v-else-if="item=='2'">仅支持公对公转账</div>
                  <div class="custom-tip" v-else-if="item=='12'">可用余额：￥{{orderInfo.balanceAmount}}</div>
                  <div class="custom-tip" v-else-if="item=='14'">支持微信/支付宝扫码支付</div>
                </div>
              </div>
            </template>
            <template #right-icon>
              <van-radio :name="item" checked-color="#FF6A00" :disabled="((item === '12' && Number(orderInfo.orderAmount) > Number(orderInfo.balanceAmount)) || guliNum/100 === Number(orderInfo.orderAmount))" />
            </template>
          </van-cell>
        </div>
        <van-cell v-if="orderInfo.payChannel.includes('15')" is-link @click="showGuLi = true">
          <div class="pay-item">
            <van-image :src="require('../assets/images/guli.png')" alt="" class="pay-icon" fit="contain" />
            <div class="pay-text">
              <div class="custom-title">
                {{getNameByCode('15', payChannelList)}}
                <span v-if="guliNum === 0" style="font-size: 12px; color: #929AAB;">去选择</span>
                <span v-else style="font-size: 12px; color: #FF6A00;">-￥{{ guliNum/100 }}</span>
              </div>
              <div class="custom-tip">共<span class="orange">{{commissionInfo.totalCommission}}</span>个</div>
            </div>
          </div>
        </van-cell>
      </van-radio-group>
    </div>

    <div v-if="showTip" class="yue-tip">
      应连续的交易要求，本公司/本人同意将交易余额中的款项优先抵做后续订单交易的款项，后续对应订单完成后，交易余额显示金额将对应减少。
    </div>
    
    <van-button
      class="payBtn"
      color="#FF6A00"
      type="primary"
      size="large"
      block
      round
      :loading="loading"
      @click="payment"
      :disabled="submitDisabled"
    >
      去支付
    </van-button>
    <form id="submitForm" v-show="false" name="submitForm" method="post" :action="actionUrl">
      <input id="packet" name="packet" :value="inputVal"/>
      <input type="submit">
    </form>
    <van-dialog v-model="showCode" confirmButtonText="关闭">
      <div class="pay-info">
        <p class="name">{{payInfo.userName}}</p>
        <p class="tip">请客户扫码完成付款</p>
        <van-image :src="codeSrc" class="code-img" />
        <p class="money">￥{{payInfo.amount}}</p>
        <p class="tip">二维码有效期至<span class="time">{{payInfo.expireTime}}</span></p>
      </div>
    </van-dialog>
    <!-- <van-dialog v-model="payVisible" :showConfirmButton="false">
      <van-cell value="请确认微信支付是否已完成" />
      <van-cell value="已完成支付" />
      <van-cell value="支付遇到问题，重新支付" />
    </van-dialog> -->

    <van-dialog v-model="showMessageDialog" title="手机验证码" :show-cancel-button="true" :show-confirm-button="false" cancel-button-text="关闭">
      <div class="code-content">
        <p class="title">已发送至尾号{{orderInfo.phone||'-'}}的手机</p>
        <van-password-input
          :value="checkCode"
          :mask="false"
          :focused="showKeyboard"
          @focus="showKeyboard = true"
        />
        <van-number-keyboard
          v-model="checkCode"
          :show="showKeyboard"
          @blur="showKeyboard = false"
          maxlength="6"
          get-container="#app"
          z-index="9999"
        />
        <div class="code-btm">
          <span class="no-message" @click="noMessage">收不到短信？</span>
          <van-button v-if="sendSeconds!==0" type="primary" size="mini" color="#FF6A00" disabled>{{sendSeconds}}秒后重发</van-button>
          <van-button v-else type="primary" size="mini" color="#FF6A00" @click="sendMessage">重新发送</van-button>
        </div>
      </div>
    </van-dialog>
    <van-popup v-model="showGuLi" round position="bottom" closeable>
      <div class="guli-popup">
        <div class="title">
          <span class="xzgl">选择谷粒</span>
        </div>
        <div class="desp">您有<span class="orange">{{commissionInfo.totalCommission}}</span>个谷粒 本次最多使用<span class="orange">{{commissionInfo.orderMaxCommission}}</span>个谷粒</div>
        <van-radio-group v-model="guliRadio">
          <van-cell title="暂不使用谷粒" clickable @click="guliRadio = '1'">
            <template #right-icon>
              <van-radio name="1" checked-color="#FF6A00" />
            </template>
          </van-cell>
          <van-cell clickable @click="guliRadio = '2'">
            <template #title>
              <div>使用<span class="orange">{{commissionInfo.orderMaxCommission}}</span>谷粒最高可抵扣<span class="orange">￥{{(commissionInfo.orderMaxCommission/100).toFixed(2)}}</span></div>
            </template>
            <template #right-icon>
              <van-radio name="2" checked-color="#FF6A00" />
            </template>
          </van-cell>
          <van-cell title="自定义使用谷粒数量" clickable @click="guliRadio = '3'">
            <template #right-icon>
              <van-radio name="3" checked-color="#FF6A00" />
            </template>
          </van-cell>
        </van-radio-group>
        <div v-if="guliRadio === '3'" class="custom-guli">
          使用<van-field v-model="guliNumInput" :maxlength="20" type="tel" input-align="center" />谷粒，抵扣<span class="orange">￥{{(guliNumInput/100).toFixed(2)}}</span>
        </div>
        <van-button
          class="payBtn"
          color="#FF6A00"
          type="primary"
          size="large"
          block
          round
          @click="selectGuLi"
        >
          确 定
        </van-button>
      </div>
    </van-popup>
  </div>

</van-pull-refresh>
</template>

<script>
import { isPC } from '@/assets/js/utils'
import { getNameByCode } from '@/assets/js/utils'
import {cashierOrderQuery,getMcode,initiatePay,scanToPay,payStatusQuery,sendCheckCode,calculateCommission} from '@/api'
import '@/assets/js/rem'
//import axios from 'axios'
export default {
  data() {
    return {
      getNameByCode,
      value: "",
      radio: "",
      loading: false,
      orderNo:'',
      orderInfo:{
        payChannel: []
      },
      payChannelList:[],
      actionUrl:'',
      inputVal:'',
      sign:'',
      refreshing: false,
      systemType:null,
      codeSrc:'',
      showCode:false,
      showMessageDialog: false,
      showKeyboard: false,
      timer: null,
      timerInterval: null,
      sendSeconds: 0,
      checkCode: '',
      showGuLi: false,
      commissionInfo: {},
      payInfo: {},
      guliRadio: '1',
      guliNumInput: 0,
      guliNum: 0,
      refreshDisabled: false,
    };
  },
  created() {
    this.systemType=isPC()?2:1
    this.orderNo=this.$route.query.orderNo || ''
    this.sign=this.$route.query.sign || ''
    getMcode({
      codeId:'A016'
    }).then(res=>{
      this.payChannelList=res
    })
    this.getOrderInfo()
  },
  mounted(){
    const token = this.$route.query.token
    if(token){
      window.sessionStorage.setItem('token', token)
    }

    const listScorll = this.$refs.listScorll;
    listScorll.addEventListener('touchstart', ()=> {
      this.refreshDisabled = true
    })
    listScorll.addEventListener('touchend', ()=> {
      this.refreshDisabled = false
    })

    document.addEventListener('plusready', () => { 
      document.addEventListener("resume", () => {
        payStatusQuery({
          orderNo:this.orderNo,
          sign:this.sign,
        }).then(res => {
          if(res.tradeState=='1'){ //已支付
            window.location.replace(this.orderInfo.h5NotifyUrl ? decodeURIComponent(this.orderInfo.h5NotifyUrl) : '')
          }
        })
        .catch(err => {
          this.$dialog.alert({
            message: err,
          })
        })
      },false)
    },false);
  },
  destoryed(){
    document.removeEventListener("resume")
    clearInterval(this.timerInterval)
  },
  computed:{
    payAmount: function(){
      return (Number(this.orderInfo.orderAmount || 0) - this.guliNum/100).toFixed(2)
    },
    submitDisabled: function(){
      return this.radio === '' && this.guliNum/100 < Number(this.orderInfo.orderAmount)
    },
    showTip: function(){
      return this.orderInfo.payChannel && this.orderInfo.payChannel.some(item=>item==='12')
    }
  },
  watch: {
    checkCode(value) {
      if (value.length === 6) {
        this.showMessageDialog = false
        this.showKeyboard = false
        this.handlePay()
      }
    },
  },
  methods: {
    getOrderInfo(){
      this.$store.commit('showLoading')
      cashierOrderQuery({
        orderNo:this.orderNo,
        sign:this.sign
      }).then(res=>{
        this.refreshing = false
        this.$store.commit('hideLoading')
        this.orderInfo=res
        if(this.orderInfo.payChannel.includes('15')){
          this.getCommission()
        }
      }).catch(err=>{
        this.refreshing = false
        this.$store.commit('hideLoading')
        this.$dialog.alert({
          message: err,
        })
      })
    },
    onRefresh(){
      this.refreshing = true
      this.getOrderInfo()
      this.radio=''
    },
    getCommission(){
      calculateCommission({
        orderNo:this.orderNo,
        sign:this.sign
      }).then(res=>{
        this.commissionInfo=res
      }).catch(err=>{
        this.$dialog.alert({
          message: err,
        })
      })
    },
    radioChange(item){
      if((item === '12' && Number(this.orderInfo.orderAmount) > Number(this.orderInfo.balanceAmount)) || this.guliNum/100 === Number(this.orderInfo.orderAmount)){
        return
      }
      this.radio = item
    },
    handlePay(){
      if(this.radio==='13'){
        this.toMiniprogram()
        return
      }
      this.$store.commit('showLoading')
      initiatePay({
        channelId:this.radio,
        orderNo:this.orderNo,
        sign:this.sign,
        updateTime:this.orderInfo.updateTime,
        systemType:this.systemType,
        checkCode:this.checkCode,
        commissionAmount: this.guliNum
      }).then(res=>{
        this.$store.commit('hideLoading')
        this.payInfo = res
        if(this.radio=='3'){ //中信
          this.inputVal=res.param
          this.actionUrl=res.payUrl
          this.$nextTick(()=>{
            document.getElementById("submitForm").submit()
          })
        } else if(this.radio=='2'){ //网银转账
          this.$router.push(`/link?orderNo=${this.orderNo}&sign=${this.sign}`)
        } else if(this.radio=='7' || this.radio=='6' || this.radio=='14'){ //支付宝（二维码） 微信（二维码） 银联（聚合码）
          let obj=JSON.parse(res.param)
          scanToPay({paymentNo:obj.paymentNo}).then(res=>{
            this.codeSrc=window.URL.createObjectURL(res)
            this.showCode=true
            this.inquireData()
          }).catch(err=>{
            this.$dialog.alert({
              message: err,
            })
          })
        } else if(this.radio=='8' || this.radio=='4'){ //支付宝（网页） 支付宝（APP）
          const div=document.createElement('div')
          div.innerHTML=res.payUrl
          document.body.appendChild(div)
          document.forms['punchout_form'].submit()
        } else if(this.radio=='9'){ //微信支付（适用除微信浏览器之外的普通浏览器）
          let mwebUrl=res.payUrl
          let redirect_url=res.param?JSON.parse(res.param).backUrl:''
          let url=redirect_url?`${mwebUrl}&redirect_url=${encodeURIComponent(redirect_url)}`:mwebUrl
          
          try {
            const origin = window.location.origin
            // 创建一个webview
            const webview = plus.webview.create('',origin); 
            webview.loadURL(url,{'Referer':origin});
            var currentWebview = plus.webview.currentWebview(); //此对象相当于html5plus里的plus.webview.currentWebview()。在uni-app里vue页面直接使用plus.webview.currentWebview()无效，非v3编译模式使用this.$mp.page.$getAppWebview()
            currentWebview.append(webview);
            webview.addEventListener('loaded',()=>{ 
              if(webview.getURL().indexOf('https://wx.tenpay.com') != -1){
                setTimeout(()=>{
                  webview.close()
                },1000)
              }
            },false)
          }
          catch(err) {
            window.location.href=url
          }
        } else {
          this.$router.replace(`/scanPaySuccess?amount=${this.payAmount}&redirect_url=${encodeURIComponent(this.orderInfo.h5NotifyUrl || '')}`)
        }
      }).catch(err=>{
        this.$store.commit('hideLoading')
        this.$dialog.alert({
          message: err,
        })
      })
    },
    payment() {
      if(this.guliNum > 0){
        this.openMessageDialog()
        return
      }else if(this.radio === '12'){
        if(this.orderInfo.role === '0' && this.orderInfo.balancePayAuth !== '1'){
          this.$dialog.alert({
            message: '该支付方式仅支持管理员操作',
          })
        }else{
          this.openMessageDialog()
        }
        return
      }else{
        this.handlePay()
      }
    },
    openMessageDialog(){
      this.checkCode = ''
      this.showMessageDialog = true
      this.showKeyboard = true
      if(this.sendSeconds === 0){
        this.sendMessage()
      }
    },
    toMiniprogram() {
      plus.share.getServices(
        (s) => {
          var shares = {};
          for (var i = 0; i < s.length; i++) {
            var t = s[i];
            shares[t.id] = t;
          }
          const isPro = (window.location.host.indexOf('pay-web.999haoche') > -1 || window.location.host.indexOf('pay-web.888haoche') > -1)
          const sweixin = shares["weixin"];
          sweixin
            ? sweixin.launchMiniProgram({
                path: `pages/pay/pay?params=${encodeURIComponent(JSON.stringify({orderNo: this.orderNo, sign: this.sign, updateTime: this.orderInfo.updateTime, commissionAmount: this.guliNum, checkCode: this.checkCode}))}`,
                type: isPro ? 0 : 2, //可取值： 0-正式版； 1-测试版； 2-体验版； 默认值为0。
                id: this.orderInfo.cmbcAppletId, //小程序的原始id
              })
            :  plus.nativeUI.alert("当前环境不支持微信操作!");
        },
        function(e) {
          console.log("获取分享服务列表失败：" + e.message);
        }
      );
    },
    inquireData () {
      const reload = () => {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.inquireData()
        }, 3000)
      }

      payStatusQuery({
        orderNo:this.orderNo,
        sign:this.sign,
      }).then(res => {
        if(res.tradeState=='1'){ //已支付
          this.showCode=false
          this.$router.replace(`/scanPaySuccess?amount=${this.payAmount}&redirect_url=${encodeURIComponent(`${this.orderInfo.h5NotifyUrl}`)}`)
        }  else {//未支付
          reload()
        }
      })
      .catch(() => {
        reload()
      })
    },
    wxPay(){
      // let that=this
      // let param={}
      // cangoCarCreateOrder(param).then(res=>{
      //   if (typeof WeixinJSBridge == "undefined"){//微信浏览器内置对象
      //     if( document.addEventListener ){
      //       document.addEventListener('WeixinJSBridgeReady', that.onBridgeReady(res), false);
      //     }else if (document.attachEvent){
      //       document.attachEvent('WeixinJSBridgeReady', that.onBridgeReady(res));
      //       document.attachEvent('onWeixinJSBridgeReady',that.onBridgeReady(res));
      //     }
      //   }else{
      //     that.onBridgeReady(res);
      //   }
      // }).catch((err)=>{
      //   this.$store.commit('hideLoading')
      //   this.$toast.fail(err)
      // })
    },
    onBridgeReady:function(data){
      //var that= this;
      window.WeixinJSBridge.invoke(
        'getBrandWCPayRequest',{
          debug:true,
          "appId":data.appId,//公众号名称，由商户传入
          "timeStamp":data.timeStamp,//时间戳
          "nonceStr":data.nonceStr,//随机串
          "package":data.packageValue,//预支付id
          "signType":data.signType,//微信签名方式
          "paySign":data.paySign,//微信签名
        },
        function(res){
          if(res.err_msg == "get_brand_wcpay_request:ok" ){         
              alert('支付完成')
          }else{           
            alert("支付失败");
          }
        }
      )
    },
    isWeiXin(){
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
    noMessage(){
      this.$dialog.alert({
        title: '信息提示',
        messageAlign: 'left',
        message: '手机验证码发送至好车平台经销商对应的管理员手机号。如手机号无误，请检查手机通信是否正常。',
        confirmButtonText: '我已知晓'
      })
    },
    sendMessage(){
      const params = {
        orderNo: this.orderInfo.orderNo,
        commissionAmount: this.guliNum/100,
        channelId: this.radio,
      }
      sendCheckCode(params)
      .then(()=>{
        this.sendSeconds = 60
        this.timerInterval = setInterval(()=>{
          this.sendSeconds--
          if(this.sendSeconds === 0){
            clearInterval(this.timerInterval)
          }
        },1000)
      }).catch(err=>{
        this.sendSeconds = 0
        clearInterval(this.timerInterval)
        this.$dialog.alert({
          message: err,
        })
      })
    },
    selectGuLi(){
      if(this.guliRadio === '1'){
        this.guliNum = 0
      }else if(this.guliRadio === '2'){
        this.guliNum = Number(this.commissionInfo.orderMaxCommission)
        if(this.guliNum/100 === Number(this.orderInfo.orderAmount)){
          this.radio = ''
        }
      }else if(this.guliRadio === '3'){
        if(this.guliNumInput === ''){
          this.$toast('请输入要使用的谷粒数')
          return
        }else if(Number(this.guliNumInput) > Number(this.commissionInfo.orderMaxCommission)){
          this.$toast(`本次最多使用${this.commissionInfo.orderMaxCommission}个谷粒`)
          return
        }else{
          this.guliNum = Number(this.guliNumInput)
        }
      }
      if(this.guliNum/100 === Number(this.orderInfo.orderAmount)){
        this.radio = ''
      }
      this.showGuLi = false
    }
  },
  beforeDestroy () {
    clearTimeout(this.timer)
    clearInterval(this.timerInterval)
  }
};
</script>

<style lang="scss" scoped>
.paymentBox {
  padding: 40px 15px 15px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .orange{
    color: #FF6A00;
  }
  .pay-channel-list{
    flex: 1;
    height: 0;
    overflow: auto;
    .van-radio-group{
      border-radius: 0.18rem;
      overflow: hidden;
      .van-cell{
        padding-top: 15px;
        padding-bottom: 15px;
        &:after{
          left:0;
          right:0;
        }
      }
    }
  }
  .receiveBox{
    font-size: 14px;
    color: #323233;
    line-height: 20px;
    margin-top: 16px;
    text-align: center;
    .receiveName{
      color: #969799;
    }
  }
  .discount{
    font-size: 0.25rem;
    font-weight: bold;
    text-align: center;
    margin-top: 0.1rem;
  }
  .payment-title{
    font-size: 12px;
    color: #323233;
    margin: 45px 0 10px;
  }
  .radio-item{
    .van-cell{
      border-bottom: 1px solid #EBEDF0;
    }
    // &:first-child{
    //   border: none;
    // }
  }
  .pay-item{
    display: flex;
    align-items: flex-start;
    .pay-icon{
      width: 20px;
      height: 20px;
      margin-top: 2px;
    }
    .pay-text{
      flex: 1;
      margin-left: 10px;
    }
  }
  .custom-tip{
    font-size: 12px;
    color: #929AAB;
    display: inline-block;
  }
  .custom-title{
    font-size: 16px;
    color: #323233;
    padding-right:0.22rem;
    display: flex;
    justify-content: space-between;
  }
  .amount {
    font-size: 18px;
    color: #000000;
    font-weight: bold;
    text-align: center;
    .num{
      font-size: 32px;
    }
  }

  .yue-tip{
    padding: 9px 18px;
    background: #FFEAED;
    border-radius: 8px;
    font-size: 12px;
    color: #FD4242;
    line-height: 17px;
    margin-top: 12px;
  }
  .payBtn{
    height: 44px;
    margin-top: 15px;
  }

  .custom-tip2{
    font-size: 0.2rem;
    color: #929AAB;
    padding-left:0.28rem;
    padding-top:0.2rem;
  }
  .pay-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.2rem;
    color: #929AAB;
    .code-img {
      padding: 0.2rem 0.6rem;
    }
    .name {
      margin-top: 0.3rem;
      font-size: 0.35rem;
      font-weight: bold;
      color: black;
    }
    .money {
      font-size: 0.5rem;
      color: black;
    }
    .time {
      color: #FD4242;
    }
  }
 
  .code-content{
    padding: 15px;
    .title{
      font-size: 0.2rem;
      color: #929AAB;
      margin-bottom: 0.2rem;
    }
    .code-btm{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0.3rem;
      .no-message{
        font-size: 0.2rem;
        color: #FF6A00;
      }
    }
  }
  .guli-popup{
    padding: 0.5rem;
    .title{
      display: flex;
      justify-content: space-between;
      .xzgl{
        font-size: 0.32rem;
        font-weight: bold;
      }
      .sygz{
        font-size: 0.2rem;
      }
    }
    .desp{
      font-size: 0.2rem;
      color: #929AAB;
      margin: 0.15rem 0 0.3rem;
    }
    .van-cell{
      padding-left: 0;
      padding-right: 0;
    }
    .custom-guli{
      font-size: 0.25rem;
      text-align: center;
      background: #F5F5F5;
      border-radius: 15px;
      padding: 0.3rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .van-cell{
        background-color: transparent;
        width: 1.5rem;
        padding: 0 0.2rem;
        border-bottom: 1px solid #979797;
      }
    }
    .payBtn{
      margin-top: 3rem;
    }
  }
}
</style>